<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3412 4.58694C13.43 4.67578 13.4804 4.79572 13.4804 4.92158V14.0532C13.4804 14.3152 13.2687 14.527 13.0066 14.527H3.52998C3.26789 14.527 3.05615 14.3152 3.05615 14.0532V1.73359C3.05615 1.47151 3.26789 1.25977 3.52998 1.25977H9.81858C9.94444 1.25977 10.0659 1.31011 10.1547 1.39895L13.3412 4.58694ZM12.3876 5.13924L9.60092 2.35253V5.13924H12.3876Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconFile'
}
</script>
